.org-input {
    @apply w-full h-7 px-2 rounded-md border-0 text-[12.8px] bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF];
}

.org-input:disabled {
    @apply w-full h-7 px-2 rounded-md border border-[#2A282B] text-[12.8px] text-white bg-[#1F1E1F] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF];
}

.org-input-placeholder::placeholder {
    color: #8a8a8a; /* Цвет placeholder */
    opacity: 1; /* Полностью видимый текст */
}

.org-input-placeholder:focus::placeholder {
    color: #8a8a8a33; /* Цвет placeholder при фокусе */
}

@media print {
    body * {
        visibility: hidden;
    }
    #printable-content,
    #printable-content * {
        visibility: visible;
    }
    #printable-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    .overflow-y-auto {
        overflow: visible !important;
        height: auto !important;
        overflow-y: auto;
    }
    .h-dvh {
        height: auto !important;
    }
}
