.square-full-icon {
    width: 11px;
}

.plus-icon {
    width: 13px;
}

.copy-icon {
    width: 13px;
}

.delete-icon {
    width: 13px;
}

.main-data {
    width: 
}