.pie-chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #eaeef2;
    padding: 20px;
    border-radius: 8px;
  }
  
  .pie-chart {
    width: 150px;
    height: 150px;
    transform: rotate(-90deg);
  }
  
  .pie-chart circle {
    transition: stroke-dasharray 0.3s;
  }
  
  .pie-chart-legend {
    list-style: none;
    padding: 0;
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
  }
  
  .pie-chart-legend li {
    margin: 4px 0;
  }