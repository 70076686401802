.struct-input {
    @apply w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF];
}

.struct-input:disabled {
    @apply w-full h-7 px-2 rounded-md border border-[#2A282B] text-sm text-white bg-[#1F1E1F] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF];
}

.struct-input-placeholder::placeholder {
    color: #8a8a8a; /* Цвет placeholder */
    opacity: 1; /* Полностью видимый текст */
}

.struct-input-placeholder:focus::placeholder {
    color: #ffffff; /* Цвет placeholder при фокусе */
}
